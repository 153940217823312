<template>
  <div class="main-content">
    <div class="centered-loading-wrapper column" v-if="loading">
      <LoadingSpinner />
      <p>Loading Components...</p>
    </div>
    <div v-else>
      <div v-if="viewToShow === 'list'" class="component-list">
        <h3 class="page-heading">Components</h3>
        <button
          class="text-icon contained primary"
          @click="createNewComponents">
          <Icon :path="mdiPlus" />
          <span class="text">Create</span>
        </button>
        <ag-grid-vue
          class="ag-theme-alpine component"
          @grid-ready="onGridReady"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="rowData"
          rowSelection="single"
          @cellClicked="onCellClicked">
        </ag-grid-vue>
      </div>
      <div v-if="viewToShow == 'editor'">
        <ComponentEditor
          :selectedComponentProp="selectedComponent"
          :selectedComponentEditProp="selectedComponentEdit"
          :isNewComponentProp="isNewComponent"
          @changesMade="refreshGrid"
          @close="showModal = ''"
          @changeView="changeView" />
      </div>
    </div>

    <teleport to="body">
      <transition name="fade">
        <YesNoDialog
          id="unsavedChanges"
          v-if="showModal === 'confirmDelete'"
          :maxWidth="'400'"
          :nameYes="'Confirm'"
          :funcYes="deleteComponent"
          :nameNo="'Cancel'"
          :funcNo="closeModal"
          @close="showModal = ''">
          <h4>Delete Component?</h4>
          <p>
            This will remove it from any items which are associated with it. Any
            existing incomplete orders which have been placed which contain this
            component will be unaffected.
          </p>
          <p style="text-align: center; padding: 1rem">
            <strong>{{ clickedRow.data.name }}</strong>
          </p>
        </YesNoDialog>
      </transition>
    </teleport>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { AgGridVue } from "ag-grid-vue3";
  import LoadingSpinner from "@/components/LoadingSpinner.vue";
  import ComponentEditor from "@/components/Menus/ComponentEditor.vue";
  import YesNoDialog from "@/components/Dialogs/YesNoDialog.vue";
  import { useToast } from "vue-toastification";
  import { mdiPlus } from "@mdi/js";
  import { OPERATORTYPEOUTLET } from "@/constants/operatorTypes.ts";
  import ArrayChips from "@/components/AggridComponents/ArrayChips.vue";

  export default {
    components: {
      AgGridVue,
      LoadingSpinner,
      ComponentEditor,
      YesNoDialog,
      /* eslint-disable vue/no-unused-components */
      ArrayChips
    },
    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        viewToShow: "list",
        columnDefs: [],
        defaultColDef: null,
        gridApi: null,
        columnApi: null,
        rowData: [],
        allAllergens: [],
        clickedRow: null,
        unsavedChanges: false,
        showModal: "",
        newComponent: {},
        executing: false,
        rowModelType: null,
        isNewComponent: false,
        selectedRowNode: null,
        selectedComponent: null,
        selectedComponentEdit: null,
        loading: true,
        mdiPlus,
      };
    },
    computed: {
      ...mapGetters({
        apiPrivateAllergenById: "apiPrivateAllergens/getById",
        apiPublicAllergensGetAll: "apiPublicAllergens/getAll",
        apiPrivateComponentsDeleteById: "apiPrivateComponents/deleteById",
        apiPrivateComponentsGetByOutletId: "apiPrivateComponents/getByOutletId",
      }),
    },
    watch: {
      operatorId: function () {
        this.refreshGrid(null);
      },
    },
    methods: {
      onGridReady(params) {
        // get the grid and column api
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
      },

      onCellClicked(row) {
        this.clickedRow = row;

        if (this.unsavedChanges == true) {
          this.handleUnsavedChanges();
        } else if (row.column.colId === "delete") {
          this.showModal = "confirmDelete";
        } else {
          this.handleNewSelection();
          this.changeView("editor");
        }
      },

      refreshGrid(component) {
        // Whatever was here before broke it,
        // This is just going to reforce the loading of the grid which I think is safer.
        // Feel free to review, is probably still not quite right anyway!


        // 0424
        this.apiPrivateComponentsGetByOutletId(this.operatorId).then((data) => {
          this.rowData = data;
          this.loading = false;
          this.selectedComponentEdit = component; // Why should I need to do this?
        });
      },

      getSelectedRowData() {
        let selectedNodes = this.gridApi.getSelectedNodes();
        let selectedData = selectedNodes.map((node) => node.data);
        this.selectedRow = selectedData[0];
      },

      creatingComponent(e) {
        this.newComponent[e.target.name] = e.target.value;
      },

      handleUnsavedChanges() {
        this.showModal = "confirmChanges";
      },

      async getAllAllergens() {
        await this.apiPublicAllergensGetAll().then((response) => {
          this.allAllergens = response;
        });
      },

      createNewComponents() {
        this.isNewComponent = true;
        this.selectedRowNode = null;
        this.gridApi.deselectAll();
        this.selectedComponent = { allergens: [] };
        this.selectedComponentEdit = { allergens: [] };
        this.changeView("editor");
      },

      deleteComponent() {
        this.showModal = "";
        this.apiPrivateComponentsDeleteById(this.clickedRow.data.id).then(
          (response) => {
            if (response.status == 200) {
              useToast().success("Component deleted.");

              const selectedRow = this.gridApi.getSelectedRows();

              this.gridApi.applyTransaction({ remove: selectedRow });
            } else if (response.status == 409) {
              this.handleItemDeleteConflict(response.data);
            } else {
              useToast().error(
                "Failed to delete component. Please contact Support."
              );
            }
          }
        );
      },

      // A new row has been selected, reset things, etc
      async handleNewSelection() {
        const rowData = this.clickedRow.data;

        this.isNewComponent = false;
        this.selectedRowNode = this.clickedRow.node;
        this.selectedComponent = rowData;
        this.selectedComponentEdit = rowData;

        this.showModal = "editAlteration";
      },

      closeModal() {
        this.showModal = "";
      },
      changeView(viewToChange) {
        if (viewToChange === "list") this.refreshGrid();
        this.viewToShow = viewToChange;
      },
    },
    async beforeMount() {
      if (this.operatorType !== OPERATORTYPEOUTLET) {
        this.$router.push({ name: "/dashboard" });
        return;
      }

      const outletId = this.operatorId;
      await this.getAllAllergens();

      this.apiPrivateComponentsGetByOutletId(outletId).then((data) => {
        this.rowData = data;
        this.loading = false;
      });

      this.defaultColDef = {
        suppressMenu: true,
        suppressMovable: true,
        floatingFilter: true,
        resizable: true,
        filter: true,
        sortable: true,
        minWidth: 100,
        flex: 2
      };

      this.columnDefs = [
        {
          field: "name",
        },

        // {
        //   field: "price",
        //   width: 100,
        //   suppressSizeToFit: true,
        //   floatingFilter: true,
        //   cellRenderer: ({ value }) => formatMoneyCell(value, this.formatMoney),
        // },

        // {
        //   field: "outOfStock",
        //   width: 140,
        //   suppressSizeToFit: true,
        //   floatingFilter: true,
        //   cellClass: ({ value }) => stringToClassName(String(value)),
        //   cellRenderer: ({ value }) =>
        //     value
        //       ? `<span class="material-icons material-icons-round">&#xE15C;</span><span>Out of stock</span>`
        //       : `<span class=material-icons material-icons-round">&#xE86C;</span><span>Available</span>`,
        // },

        {
          field: "allergens",
          valueGetter: ({ data }) =>  this.allAllergens.filter(({ id }) => data.allergens?.includes(id)).map(({ name }) => name),
          cellRenderer: 'ArrayChips',
        },

        {
          field: "dietaryType",
          cellRenderer: 'ArrayChips',
        },

        {
          field: "delete",
          flex: 1,
          minWidth: 100,
          filter: false,
          cellClass: "action",
          headerName: "",
          cellRenderer: () => {
            return '<button type="button" class="text-icon small inline"><span class="icon"><span class="mdi-icon" role="img"><svg fill="#333" class="material-design-icon__svg" width="18" height="18"viewBox="0 0 24 24"><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg></span></span><span class="text">Delete</span></button>';
          },
        },
      ];
    },
  };
</script>

<style lang="scss">
  .prompt {
    display: flex;
    justify-content: space-around;
    margin-top: 3rem;
  }

  .modal-content-detail {
    margin: 0 auto;
    background: #f0f0f0;
    padding: 1rem;
    li {
      margin-bottom: 1rem;
    }
    span.title {
      color: #666;
      padding-right: 10px;
      width: 100px;
      display: inline-block;
    }
    span.price {
      &::before {
        display: inline-block;
      }
    }
  }

  .ag-theme-alpine {
    margin-top: 1rem;
    height: $table_height;
  }

  .component-list {
    margin: 1rem 0;
  }

  .allergen-chip,
  .dietary-chip {
    white-space: nowrap !important;
  }
</style>
