<template>
  <div>
    <div
      class="centered-loading-wrapper column"
      v-if="loading"
    >
      <LoadingSpinner />
      <p>{{ actionName }} {{ selectedComponentEdit?.name }}...</p>
    </div>
    <div v-else>
      <div class="fixed-header column">
        <BackToPageLink
          title="Back To Component List"
          @close="navigateTo"
        />
        <div class="justify-space-between row">
          <div class="name row">
            <label
              for="name"
              class="row"
            >
              Name
              <span class="required-sign">*</span>
            </label>
            <input
              type="text"
              class="name"
              name="name"
              placeholder="Enter Component Name"
              v-model="selectedComponentEdit.name"
              @input="setUnsavedChanges(true)"
            />
          </div>
          <div class="actions row">
            <button
              type="button"
              class="text-icon contained save-btn"
              :disabled="saving || !selectedComponentEdit.name || !unsavedChanges
                "
              @click="saveComponent()"
            >
              <span class="icon">
                <Icon
                  :path="mdiCheck"
                  :size="20"
                />
              </span>
              <span class="text">Save</span>
              <LoadingSpinner v-if="saving" />
            </button>
          </div>
        </div>
      </div>

      <div class="content-wrapper">
        <div class="content">
          <div class="input-wrapper row baseline no-wrap">
            <label for="description">Description</label>
            <textarea
              name=""
              id=""
              cols="10"
              rows="3"
              v-model="selectedComponentEdit.description"
              placeholder="Add a description for the component"
              @input="setUnsavedChanges(true)"
            ></textarea>
          </div>
          <div class="detail-table">
            <div class="row">
              <div class="allergens config">
                <div class="input-wrapper">
                  <label for="allergens"> Allergens</label>
                  <div class="input-wrapper_options row">
                    <div
                      class="checkbox-wrapper-unit"
                      :class="{ over18: allergen.name === 'Alcohol' }"
                      v-for="allergen in allergens"
                      :key="`allergen-${allergen.id}`"
                    >
                      <label :for="`allergen-${allergen.id}`">
                        <input
                          type="checkbox"
                          :id="`allergen-${allergen.id}`"
                          v-model="allergen.checked"
                          @input="setUnsavedChanges(true)"
                        />

                        <p class="row">
                          {{ allergen.name }}
                        </p>
                      </label>
                      <span
                        class="o18 row"
                        v-if="allergen.name == 'Alcohol'"
                      >
                        18+
                        <SmallTipPopUp text="Alcohol will be only served at least 18 years old." />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="dietaries config">
                <div class="input-wrapper">
                  <label for="dietaries">Dietary</label>
                  <div class="input-wrapper_options row">
                    <label
                      class="checkbox-wrapper-unit"
                      v-for="diet in dietaries"
                      :key="`dietary-${diet.code}`"
                      :for="`dietary-${diet.code}`"
                    >
                      <input
                        type="checkbox"
                        v-model="diet.checked"
                        name="dietary"
                        :value="diet.code"
                        :id="`dietary-${diet.code}`"
                        @input="setUnsavedChanges(true)"
                      />
                      <p>
                        {{ diet.displayValue }}
                      </p>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="row">
              <div class="unit-type config">
                <div class="input-wrapper">
                  <label for="unit-type">Unit Type</label>
                  <CustomDropDown
                    :options="UNITTYPES"
                    :value="selectedComponentEdit.unitType"
                    @onChange="handleChangeUnitType" />
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <teleport to="body">
    <transition name="fade">
      <ModalDialog
        :maxWidth="'600'"
        :hideCloseButton="true"
        v-if="showModal === 'showAffectedOrders'"
      >
        <template v-slot:title>Orders Affected</template>
        <ConfirmCancellation
          :affectedOrders="affectedOrders"
          @close="showModal = ''"
          @saveExistingItemConfirmed="saveExistingComponentConfirmed"
        >
          <template v-slot:message>This change has been determined as high risk. High risk changes
            include things such as adding allergens or changing dietary
            types.</template>
        </ConfirmCancellation>
      </ModalDialog>
    </transition>
  </teleport>
</template>

<script>
import store from "@/store";
import { useToast } from "vue-toastification";
import { mapGetters } from "vuex";
import Enumerable from "linq";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import SmallTipPopUp from "@/components/SmallTipPopUp.vue";
import ConfirmCancellation from "@/components/ConfirmCancellation";
import ModalDialog from "@/components/Dialogs/ModalDialog";
import BackToPageLink from "@/components/BackToPageLink.vue";
import { mdiCheck } from "@mdi/js";
import UnsavedChangesMixin from "@/mixins/UnsavedChangesMixin";
// import CustomDropDown from "@/components/CustomDropDown";
// import { UNITTYPES } from "@/constants/unitTypes.ts";

export default {
  mixins: [UnsavedChangesMixin],
  data() {
    return {
      operatorType: this.$route.params.operatorType,
      operatorId: parseInt(this.$route.params.operatorId),
      selectedComponent: this.selectedComponentProp,
      selectedComponentEdit: this.selectedComponentProp,
      isNewComponent: this.isNewComponentProp,
      allergens: [],
      dietaries: [],
      showModal: "",
      affectedOrders: [],
      saving: false,
      actionName: "Loading",
      loading: true,
      // selectedUnitType: this.selectedComponentProp.unitType,
      mdiCheck,
      // UNITTYPES,
    };
  },
  components: {
    LoadingSpinner,
    ModalDialog,
    ConfirmCancellation,
    SmallTipPopUp,
    BackToPageLink,
    // CustomDropDown,
  },
  watch: {
    selectedComponentProp: function () {
      this.updateLocalVars();
    },
    selectedComponentEditProp: function () {
      this.updateLocalVars();
    },
  },
  props: {
    selectedComponentProp: {
      type: Object,
      default: null,
    },
    selectedComponentEditProp: {
      type: Object,
      default: null,
    },
    isNewComponentProp: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close", "changesMade", "changeView"],
  computed: {
    ...mapGetters({
      apiPublicAllergensGetAll: "apiPublicAllergens/getAll",
      // apiPublicDietariesGetAll: "apiPublicDietaries/getAll",
    }),
  },
  methods: {
    updateLocalVars() {
      this.selectedComponent = Object.assign({}, this.selectedComponentProp);
      this.selectedComponentEdit = Object.assign(
        {},
        this.selectedComponentEditProp
      );

      this.apiPublicAllergensGetAll().then((response) => {
        this.allergens = response;
        if (this.selectedComponentEdit.allergens) {
          this.allergens.forEach((allergen) => {
            if (this.selectedComponentEdit.allergens.includes(allergen.id)) {
              allergen.checked = true;
            }
          });
        }
        this.loading = false;
      });

      store.state.apiPrivate.client.endpoints.enums
        .getDietaryTypeFlags()
        .then((response) => {
          if (response.status == 200) {
            return response.data.data;
          }
        })
        .then((data) => {
          if (data.length > 0) {
            this.dietaries = [];
            data.forEach((diet) => {
              if (this.selectedComponentEdit.dietaryType) {
                diet.checked =
                  this.selectedComponentEdit.dietaryType.includes(
                    diet.identifier
                  );
              }

              this.dietaries.push(diet);
            });
          } else {
            this.dietaries = [];
          }
        });
    },
    saveComponent() {
      if (!this.selectedComponentEdit.name) {
        useToast().error("Name is a required field");
        return;
      }

      this.saving = true;

      const save = this.isNewComponent
        ? this.saveNewComponent
        : this.saveExistingComponent;
      save();
    },
    saveExistingComponent() {
      this.selectedComponentEdit.allergens = this.allergens
        .filter(({ checked }) => checked)
        .map(({ id }) => id);

      this.selectedComponentEdit.dietaryType = this.dietaries
        .filter(({ checked }) => checked)
        .map(({ identifier }) => identifier);

      return store.state.apiPrivate.client.endpoints.components
        .update(this.selectedComponentEdit, this.selectedComponentEdit.id)
        .then((response) => {
          if (response.status == 200) {
            this.$emit("close", true);
            this.$emit("changesMade", this.selectedComponentEdit);
            store.dispatch(
              "apiPrivateComponents/upsert",
              this.selectedComponentEdit
            );
            useToast().success("Component saved.");
          } else if (response.status == 409) {
            this.handleComponentSaveConflict(response.data);
          } else {
            useToast().error(
              "Failed to save component. Please contact Support."
            );
          }
          this.saving = false;
          this.setUnsavedChanges(false);
        });
    },
    handleComponentSaveConflict(payload) {
      useToast().warning("Could not save item due to existing orders.");

      this.showModal = "showAffectedOrders";
      this.affectedOrders = payload.meta["affected-orders"].$values;
    },
    saveNewComponent() {
      var newComponent = {
        name: this.selectedComponentEdit.name,
        description: this.selectedComponentEdit.description,
        price: this.selectedComponentEdit.price,
        allergens: Enumerable.from(this.allergens.filter((x) => x.checked))
          .select((x) => x.id)
          .toArray(),
        dietaryType: Enumerable.from(this.dietaries.filter((x) => x.checked))
          .select((x) => x.code)
          .toArray(),
        unitType: this.selectedComponentEdit.unitType,
      };

      return store.state.apiPrivate.client.endpoints.outletComponents
        .create(newComponent, this.operatorId)
        .then((response) => {
          if (response.status == 201) {
            this.selectedComponent.id = response.data.data.id;
            this.isNewComponent = false;
            this.saving = false;

            this.setUnsavedChanges(false);
            this.selectedComponent = Object.assign(
              {},
              this.selectedComponentEdit
            );
            useToast().success("Component Created.");
            // TODO: update parent component
          } else {
            useToast().error(
              "Failed to create Component. Please contact Support."
            );
          }
        });
    },
    saveExistingComponentConfirmed() {
      store.state.apiPrivate.client.endpoints.components
        .update(
          this.selectedComponentEdit,
          this.selectedComponentEdit.id,
          "affected-orders=cancel"
        )
        .then((response) => {
          if (response.status == 200) {
            this.$emit("close", true);
            this.$emit("changesMade", this.selectedComponentEdit);
            store.dispatch(
              "apiPrivateComponents/upsert",
              this.selectedComponentEdit
            );
            useToast().success("Component saved.");
          } else {
            useToast().error(
              "Failed to save component. Please contact Support."
            );
          }
          this.saving = false;
          this.setUnsavedChanges(false);
        });
    },
    updateOutOfStockState(value) {
      // this type-check function should be removed after Toggle component bug is fixed
      if (typeof value === "boolean") {
        this.selectedComponentEdit.outOfStock = value;
        this.setUnsavedChanges(true);
      }
      return value;
    },
    handleChangeUnitType(newType) {
      this.setUnsavedChanges(true);
      this.selectedComponentEdit.unitType = newType.value;
    },
  },
  mounted() {
    this.updateLocalVars();
  },
};
</script>

<style lang="scss" scoped>
.fixed-header {
  display: flex;
  z-index: 2;
  gap: 1rem;

  background: #fff;
  position: sticky;
  top: calc($topbar_height - 1px);
  right: initial;

  width: calc(100% + 20px);
  margin-left: -10px;
  margin-top: -30px;
  padding: 20px 10px 1rem;
  border-bottom: 1px solid $col_faded;

  input {
    color: #000;
    margin-bottom: 0;

    &:disabled {
      background: none;
      border-radius: 0;
      padding: 0;
      margin: 0;
    }
  }

  .name,
  .actions {
    gap: 1rem;
  }

  .actions {
    button {
      .mobile-layout & {
        font-size: 0.8rem;
        justify-content: stretch;
      }
    }

    @media screen and (max-width: 1279px) {
      font-size: 0.8rem;
      gap: 0;
    }

    @media screen and (max-width: 990px) {
      position: fixed;
      bottom: 1rem;
      right: 1rem;
      background: #fff;
      box-shadow: $fixed_button_box_shadow;
      border-radius: $card_radius;
      z-index: 100;
      padding: 5px;
      gap: 5px;
    }

    @media screen and (max-width: 660px) {
      position: fixed;
      bottom: 1rem;
      right: 1rem;
      background: #fff;
      box-shadow: $fixed_button_box_shadow;
      border-radius: $card_radius;
      z-index: 100;
      padding: 5px;
      gap: 5px;
    }
  }

  .name {
    flex-wrap: nowrap;

    .app-container.mobile-layout & {
      width: 100%;
    }

    &:disabled {
      font-weight: bold;
      font-size: 1.5em;
    }
  }
}

.content-wrapper {
  margin-top: 2rem;
}

.input-wrapper {
  margin-bottom: 1rem;

  p,
  input {
    margin: 0;
  }

  input[name="price"] {
    width: 100px;

    @media screen and (max-width: 768px) and (min-width: 480px) {
      width: 80px;
    }
  }

  input[type="checkbox"] {
    margin: 0;
    padding: 0;
  }

  &_options {
    gap: 20px;
    flex-wrap: wrap;

    label {
      font-weight: initial;
      margin-bottom: 0 !important;
      width: max-content;
    }
  }

  textarea {
    margin-bottom: 0;
  }
}

.allergens>.row {
  align-items: flex-start;
}

.config {
  .input-wrapper_options {
    gap: 5px;
  }

  label[for="allergens"],
  label[for="dietaries"],
  label[for="unit-type"] {
    margin-bottom: 0.5rem;
  }

  .over18 {
    background: $col_alpha-lightest;

    .o18 {
      color: $col_delta;
      font-weight: 600;
      gap: 3px;
      padding-left: 0.5rem;

      .material-icons {
        font-size: 0.875rem;
        font-weight: normal;
        color: $col_alpha_darker;
      }
    }
  }
}

label[for="name"] {
  font-weight: bold;
  width: 90px;
  font-size: 0.875rem;
}

button.save-btn {
  position: absolute;
  right: 60px;

  @media screen and (max-width: 425px) {
    padding: 0.1rem 0.5rem;
    bottom: 1rem;
    right: 1rem;

    .text {
      display: none;
    }
  }
}

@media screen and (max-width: 425px) {
  .input-wrapper.row {
    flex-direction: column !important;
    flex-wrap: wrap;
    align-items: baseline;

    label {
      margin-bottom: 1rem;
    }

    input,
    textarea,
    select {
      width: 100%;
    }
  }
}
</style>
